import { type MongoAbility, createMongoAbility } from "@casl/ability";
import { createContextualCan } from "@casl/react";
import type { Channel, Socket } from "phoenix";
import React, { useContext } from "react";
import type { Fetcher } from "react-router";
import invariant from "tiny-invariant";
import type { BPAFieldHandler } from "~/components/bpa/handler";
import type { TableSortState } from "~/components/numbered-table";
import type { ErrorsWithChangeset } from "~/components/remix-form/errors";
import type {
  CandidateQuery,
  ContentTopicFrameQuery,
  CurrentUserFieldsFragment,
  EditInvoiceFieldsFragment,
  EmployeeFieldsFragment,
  EstimateFieldsFragment,
  KpiSectionFieldsFragment,
  ProjectFrameFieldsFragment,
  ShowContactFieldsFragment,
  ShowTrainingFieldsFragment
} from "~/types/api";

export interface KpiContextType {
  userId?: string;
  globalSet?: string;
  subset?: string;
  year: number;
  showWho: () => boolean;
}
export type ProjectContextType = ProjectFrameFieldsFragment | null;
export type TrainingContextType = ShowTrainingFieldsFragment | undefined;

export const UpdateAvailableContext = React.createContext<boolean>(false);

export const TrainingContext =
  React.createContext<TrainingContextType>(undefined);

export const ProjectContext = React.createContext<ProjectContextType>(null);

export const useProject = () => {
  const project = useContext(ProjectContext);
  invariant(project, "ProjectContext is required");
  return project;
};

export const useOptionalProject = () => {
  const project = useContext(ProjectContext);
  return project;
};

export const RemixFormContext = React.createContext<{
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  data?: Record<string, any> | null;
  errors?: ErrorsWithChangeset | string | null;
  state: "idle" | "loading" | "done" | "errors" | "uploading";
  fieldErrors?: Record<string, string[]>;
  fetcher?: Fetcher;
  dirty: boolean;
  setDirty: React.Dispatch<React.SetStateAction<boolean>>;
  uploading: boolean;
  setUploading: React.Dispatch<React.SetStateAction<boolean>>;
} | null>(null);

export const FormChangeContext = React.createContext<
  | null
  | ((
      eventOrName:
        | string
        | React.ChangeEvent<HTMLInputElement & { webkitdirectory?: string }>
        | React.ChangeEvent<HTMLSelectElement>,
      value: unknown,
      extra: unknown
    ) => void)
>(null);

export const FormSaveWarningContext = React.createContext(false);

export type EmployeeContextType =
  | (EmployeeFieldsFragment & { scope: "Employee" | "Manager" })
  | undefined;
export const EmployeeContext =
  React.createContext<EmployeeContextType>(undefined);

export const CandidateContext = React.createContext<
  CandidateQuery["candidate"] | undefined
>(undefined);

export const useCandidate = () => {
  const candidate = useContext(CandidateContext);
  invariant(candidate, "Candidate Context is required");
  return candidate;
};

export const useOptionalCandidate = () => {
  const candidate = useContext(CandidateContext);
  return candidate;
};

export const useEmployee = () => {
  const employee = useContext(EmployeeContext);
  invariant(employee, "Employee Context is required");
  return employee;
};
export const useOptionalEmployee = () => {
  const employee = useContext(EmployeeContext);
  return employee;
};

export const EstimateContext = React.createContext<
  EstimateFieldsFragment | undefined
>(undefined);
export const useEstimate = () => {
  const estimate = useContext(EstimateContext);
  invariant(estimate, "Estimate Context is required");
  return estimate;
};
export const CurrentUser =
  React.createContext<CurrentUserFieldsFragment | null>(null);

export const InvoiceReviewContext = React.createContext<{
  invoice: EditInvoiceFieldsFragment;
  selectedIds: string[];
  expertIds: string[];
  toggleId: (id: string) => void;
  clearIds: () => void;
  locked: boolean;
} | null>(null);

export const KpiSectionContext =
  React.createContext<KpiSectionFieldsFragment | null>(null);

export const KpiContext = React.createContext<KpiContextType | null>(null);

export const ScoreboardUserContext = React.createContext<
  { id: string; login: string } | undefined
>(undefined);

export type ContentTopicContextType =
  | ContentTopicFrameQuery["contentTopic"]
  | undefined;

export const ContentTopicContext =
  React.createContext<ContentTopicContextType>(undefined);

export const useOptionalContentTopic = () => {
  const contentTopic = useContext(ContentTopicContext);
  return contentTopic;
};

export const RotatingContext = React.createContext<
  [string[], React.Dispatch<React.SetStateAction<string[]>>] | undefined
>(undefined);

export const ContactContext = React.createContext<
  ShowContactFieldsFragment | undefined
>(undefined);

export const NavContext = React.createContext<{
  stale: boolean;
  subnav: HTMLDivElement | null;
}>({ stale: false, subnav: null });

export const ModalContext = React.createContext<{ close: () => void }>({
  close: () => {}
});

export const NumberedTableContext = React.createContext<
  | {
      onSort?: (value: string, shift?: boolean) => void;
      sort?: TableSortState;
    }
  | undefined
>(undefined);

export const TabContext = React.createContext<string>("");

export const ViewerContext = React.createContext<{
  setAttachedFile: React.Dispatch<React.SetStateAction<string | null>>;
  currentAttachedFile?: string | null;
} | null>(null);

export const BPALinksContext = React.createContext<BPAFieldHandler | undefined>(
  undefined
);

export const SocketContext = React.createContext<{
  socket?: Socket;
  appChannel?: Channel;
}>({ socket: undefined, appChannel: undefined });

export const useSocket = () => useContext(SocketContext);

export const NotesContext = React.createContext<{
  arScope?: string;
  placeholder?: string;
}>({
  arScope: undefined,
  placeholder: undefined
});

export const AbilityContext = React.createContext<MongoAbility>(
  createMongoAbility()
);
export const Can = createContextualCan(AbilityContext.Consumer);
